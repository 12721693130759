import jwtDecode from 'jwt-decode'

export function isValid(token) {
  if (!token) {
    return false
  }
  const { access_token } = token

  const decoded = jwtDecode(access_token)

  const expiresIn = decoded.exp * 1000

  if (expiresIn < Date.now()) {
    return false
  }

  return true
}

export function getLocalAccessToken() {
  const token = JSON.parse(localStorage.getItem('token'))
  return token
}

export function setLocalToken(token) {
  localStorage.setItem('token', JSON.stringify(token))
}

export function removeLocalToken() {
  localStorage.removeItem('token')
}
