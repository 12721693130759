<template>
  <v-dialog v-model="model" width="360" persistent>
    <v-card>
      <v-card-item>
        <v-card-title data-cy="card-title">{{ title }}</v-card-title>
      </v-card-item>
      <v-card-text data-cy="card-message">
        {{ message }}
      </v-card-text>

      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn
          size="large"
          color="error"
          rounded="pill"
          variant="text"
          class="text-none"
          data-cy="card-action-button-reject"
          @click="$emit('click', false)"
        >
          Não
        </v-btn>
        <v-btn
          size="large"
          variant="flat"
          rounded="pill"
          color="primary"
          class="text-none"
          data-cy="card-action-button-confirm"
          @click="$emit('click', true)"
        >
          Sim
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'ConfirmationDialog',
    props: {
      dialogModel: {
        type: Boolean,
        default: false,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      message: {
        type: String,
      },
    },
    emits: ['click'],
    computed: {
      model() {
        return this.dialogModel
      },
    },
  }
</script>

<style></style>
