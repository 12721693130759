<template>
  <v-container class="fill-height justify-center align-start align-center">
    <v-sheet
      class="rounded-xl grid-two-columns overflow-hidden height-720 p-8 p-sm-0"
      :elevation="displayXS ? '0' : '1'"
      width="960"
    >
      <aside class="d-none d-sm-block bg-app-purple-1 pa-16">
        <h2 class="font-weight-medium mt-30">Busca Auto</h2>

        <h1 class="mt-8 text-h4 pt-14">
          Tudo para o seu automóvel em um só lugar
        </h1>

        <p class="text-subtitle text-app-purple-3 mt-8">
          Anúncios de peças para automóveis é aqui
        </p>
      </aside>

      <main class="pa-0 pa-sm-16 d-flex align-center justify-center">
        <v-form @submit.prevent="onSubmit" class="flex-grow-1 flex-strech-0">
          <fieldset class="py-5">
            <legend class="text-h5 font-weight-bold">Registre-se</legend>
            <AppStepper
              :errors="form.isInvalidSubmitted ? layout.form.errorMessages : []"
              :steps="layout.stepperComponent.steps"
              v-model:step="layout.stepperComponent.step"
            />

            <template v-if="layout.stepperComponent.step == 0">
              <v-radio-group
                inline
                color="#552de2"
                hide-details="auto"
                v-model="role"
                :error-messages="form.isInvalidSubmitted ? errors?.role : ''"
              >
                <v-radio label="Empresa" value="empresa"></v-radio>
                <v-radio
                  label="Profissional Autônomo"
                  value="profissionalAutonomo"
                ></v-radio>
              </v-radio-group>

              <v-text-field
                label="Nome"
                class="my-2"
                variant="outlined"
                v-model.trim="name"
                color="deep-purple"
                hide-details="auto"
                density="comfortable"
                :error-messages="form.isInvalidSubmitted ? errors?.name : ''"
              ></v-text-field>

              <v-text-field
                class="my-2"
                label="Email"
                data-cy="email"
                variant="outlined"
                color="deep-purple"
                hide-details="auto"
                v-model.trim="email"
                density="comfortable"
                :error-messages="form.isInvalidSubmitted ? errors?.email : ''"
              ></v-text-field>

              <v-text-field
                class="my-2"
                label="Telefone"
                variant="outlined"
                color="deep-purple"
                hide-details="auto"
                v-model.trim="phone"
                density="comfortable"
                :error-messages="form.isInvalidSubmitted ? errors?.phone : ''"
              ></v-text-field>

              <v-text-field
                v-if="role === 'empresa'"
                label="CNPJ"
                class="my-2"
                variant="outlined"
                hide-details="auto"
                color="deep-purple"
                v-model.trim="cnpj"
                density="comfortable"
                :error-messages="form.isInvalidSubmitted ? errors?.cnpj : ''"
              ></v-text-field>

              <v-text-field
                v-else
                label="CPF"
                class="my-2"
                variant="outlined"
                v-model.trim="cpf"
                hide-details="auto"
                color="deep-purple"
                density="comfortable"
                :error-messages="form.isInvalidSubmitted ? errors?.cpf : ''"
              ></v-text-field>

              <v-text-field
                class="my-2"
                label="Senha"
                type="password"
                data-cy="password"
                variant="outlined"
                hide-details="auto"
                color="deep-purple"
                density="comfortable"
                v-model.trim="password"
                :error-messages="
                  form.isInvalidSubmitted ? errors?.password : ''
                "
              ></v-text-field>

              <v-btn
                block
                size="large"
                color="#552de2"
                data-cy="button-next"
                class="dark white-text text-transform-none"
                @click="layout.stepperComponent.step += +1"
              >
                Próximo
              </v-btn>
            </template>

            <template v-else>
              <v-text-field
                label="Cep"
                class="my-2"
                variant="outlined"
                color="deep-purple"
                hide-details="auto"
                density="comfortable"
                v-model.trim="zipCode"
                :error-messages="form.isInvalidSubmitted ? errors?.zipCode : ''"
              ></v-text-field>

              <v-text-field
                class="my-2"
                label="Cidade"
                variant="outlined"
                color="deep-purple"
                hide-details="auto"
                v-model.trim="city"
                density="comfortable"
                :error-messages="form.isInvalidSubmitted ? errors?.city : ''"
              ></v-text-field>

              <v-text-field
                class="my-2"
                label="Bairro"
                variant="outlined"
                color="deep-purple"
                hide-details="auto"
                density="comfortable"
                :error-messages="
                  form.isInvalidSubmitted ? errors.neighborhood : ''
                "
                v-model.trim="neighborhood"
              ></v-text-field>

              <v-text-field
                label="Rua"
                class="my-2"
                variant="outlined"
                color="deep-purple"
                hide-details="auto"
                v-model.trim="street"
                density="comfortable"
                :error-messages="
                  form.isInvalidSubmitted ? errors.neighborhood : ''
                "
              ></v-text-field>

              <v-text-field
                class="my-2"
                label="Número"
                variant="outlined"
                color="deep-purple"
                hide-details="auto"
                density="comfortable"
                :error-messages="
                  form.isInvalidSubmitted ? errors.addressNumber : ''
                "
                v-model,trim="addressNumber"
              ></v-text-field>

              <v-text-field
                class="my-2"
                variant="outlined"
                label="Complemento"
                color="deep-purple"
                hide-details="auto"
                density="comfortable"
                :error-messages="
                  form.isInvalidSubmitted ? errors.addressComplement : ''
                "
                v-model.trim="addressComplement"
              ></v-text-field>

              <p
                :key="i"
                class="px-4 mb-2 text-error"
                :data-cy="'error-message'"
                v-for="(error, i) in layout.form.errorMessages"
              >
                {{ error.message }}
              </p>

              <v-btn
                block
                size="large"
                type="submit"
                color="#552de2"
                data-cy="button-submit"
                :loading="layout.isLoading"
                :disabled="layout.isLoading"
                class="dark white-text text-transform-none"
              >
                Registrar
              </v-btn>
            </template>
          </fieldset>
          <p>
            Já possui cadastro?
            <a @click="$router.push({ name: 'login' })" class="link">
              Faça login.
            </a>
          </p>
        </v-form>
      </main>
    </v-sheet>
  </v-container>
</template>

<script>
  import { defineComponent, reactive, watch } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'

  import AppStepper from '@/components/AppStepper.vue'

  import { useForm, useField } from 'vee-validate'
  import * as yup from 'yup'

  export default defineComponent({
    name: 'LoginView',

    components: {
      AppStepper,
    },

    setup() {
      const signUpSchema = yup.object({
        // role: yup.string().required('Campo obrigatório.'),
        // name: yup.string().required('Campo obrigatório.'),
        email: yup
          .string()
          .email('Email inválido!')
          .required('Campo obrigatório.'),
        // cnpj: yup
        //   .string()
        //   .length(14, 'CNPJ inválido')
        //   .when('role', {
        //     is: (value) => value === 'empresa',
        //     then: (schema) => schema.required('Campo obrigatório.'),
        //     otherwise: (schema) => schema,
        //   }),
        // cpf: yup
        //   .string()
        //   .length(11, 'CPF inválido')
        //   .when('role', {
        //     is: (value) => value === 'profissionalAutonomo',
        //     then: (schema) => schema.required('Campo obrigatório'),
        //   }),
        // phone: yup
        //   .string()
        //   .length(11, 'Telefone invállido')
        //   .required('Campo obrigatorio'),
        password: yup.string().required('Campo obrigatório.'),
        // zipCode: yup.string().required('Campo obrigatório'),
        // city: yup.string().required('Campo obrigatório'),
        // neighborhood: yup.string().required('Campo obrigatório'),
        // street: yup.string().required('Campo obrigatório')
        // addressNumber: yup
        //   .number()
        //   .transform((value) =>
        //     isNaN(value) || value === null || value === undefined ? 0 : value
        //   )
        //   .positive('Este campo deve maior que zero')
        //   .required('Campo obrigatório'),
        // addressComplement: yup.string().required('Campo obrigatório.'),
      })

      const { handleSubmit, setFieldValue, errors, submitCount } = useForm({
        initialValues: {
          role: 'empresa',
        },
        validationSchema: signUpSchema,
      })

      const { value: role } = useField('role')
      const { value: name } = useField('name')
      const { value: email } = useField('email')
      const { value: cnpj } = useField('cnpj')
      const { value: cpf } = useField('cpf')
      const { value: phone } = useField('phone')
      const { value: password } = useField('password')
      const { value: zipCode } = useField('zipCode')
      const { value: city } = useField('city')
      const { value: neighborhood } = useField('neighborhood')
      const { value: street } = useField('street')
      const { value: addressNumber } = useField('addressNumber')
      const { value: addressComplement } = useField('addressComplement')

      const store = useStore()
      const router = useRouter()

      const layout = reactive({
        isLoading: false,
        stepperComponent: {
          steps: [
            { id: 0, icon: 'mdi-account', text: 'Dados pessoais' },
            { id: 1, icon: 'mdi-card-account-details', text: 'Endereço' },
          ],
          step: 0,
        },
        form: {
          errorMessages: [],
        },
      })

      const onSubmit = handleSubmit((values, actions) => {
        store
          .dispatch('auth/signUp', {
            email: values.email,
            password: values.password,
          })
          .then(() => {
            router.push('/login')
          })
          .catch((e) => {
            layout.form.errorMessages.push({
              step: 0,
              message: 'Email já cadastrado.',
            })
          })
          .finally(() => {
            layout.isLoading = false
          })
      })

      watch(
        () => errors,
        (newValue) => {
          if (
            newValue?._value?.role ||
            newValue?._value?.name ||
            newValue?._value?.email ||
            newValue?._value?.phone ||
            newValue?._value?.cpf ||
            newValue?._value?.cnpj ||
            newValue?._value?.password
          ) {
            const errorAlreadyAdded = layout.form.errorMessages.filter(
              (error) => error.step === 0
            )[0]
            if (!errorAlreadyAdded) {
              layout.form.errorMessages.push({
                step: 0,
                message: 'Um dos campos de Dados pessoais está inválido',
              })
            }
          }

          if (
            newValue?._value?.zipCode ||
            newValue?._value?.city ||
            newValue?._value?.neighborhood ||
            newValue?._value?.street ||
            newValue?._value?.addressNumber ||
            newValue?._value?.addressComplement
          ) {
            const errorAlreadyAdded = layout.form.errorMessages.filter(
              (error) => error.step === 1
            )[0]
            if (!errorAlreadyAdded) {
              layout.form.errorMessages.push({
                step: 1,
                message: 'Um dos campos de Dados pessoais está inválido',
              })
            }
          }
        },
        { deep: true }
      )

      watch(
        () => layout.stepperComponent.step,
        () => {
          if (layout.form.errorMessages.length > 0) {
            layout.form.errorMessages = []
          }
        }
      )
      return {
        setFieldValue,
        submitCount,
        onSubmit,
        errors,

        cpf,
        city,
        role,
        name,
        cnpj,
        email,
        phone,
        street,
        zipCode,
        password,
        neighborhood,
        addressNumber,
        addressComplement,

        layout,
      }
    },

    data: () => ({
      form: {
        isInvalidSubmitted: false,
        supplier: {
          empresa: 'empresa',
          profissionalAutonomo: 'profissionalAutonomo',
        },
      },
    }),

    computed: {
      displayXS() {
        return this.$vuetify.display.xs
      },
    },

    watch: {
      role(newValue) {
        if (this.cnpj && newValue === this.form.supplier.profissionalAutonomo) {
          this.setFieldValue('cnpj', null)
        }
        if (this.cpf && newValue === this.form.supplier.empresa) {
          this.setFieldValue('cpf', null)
        }
      },

      submitCount(newValue) {
        if (newValue > 0) {
          this.form.isInvalidSubmitted = true
        }
      },
    },
  })
</script>

<style scoped>
  .grid-two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .height-720 {
    min-height: 45rem;
  }

  .mt-30 {
    margin-top: 7.5rem;
  }

  fieldset {
    border: none;
  }

  legend {
    text-align: center;
  }

  ::v-deep .white-text {
    color: white;
  }

  .link {
    color: var(--app-purple-2);
    text-decoration: underline;
    cursor: pointer;

    transition: filter 300ms ease;
  }

  .link {
    color: var(--app-purple-2);
    text-decoration: underline;
    cursor: pointer;

    transition: filter 300ms ease;
  }

  .link:hover {
    filter: brightness(0.65);
  }

  @media (max-width: 600px) {
    .grid-two-columns {
      grid-template-columns: 1fr;
    }

    .height-720 {
      min-height: max-content;
    }
  }
</style>
