export function paginateByDate(products) {
  const productsSorted = products.sort((a, b) => {
    if (new Date(a.updated_at) < new Date(b.updated_at)) {
      return 1
    } else if (new Date(a.updated_at) > new Date(b.updated_at)) {
      return -1
    }

    return 0
  })

  const paginatedProducts = [[]]
  const productsPerPage = 6

  const lastPage = Math.ceil(products.length / productsPerPage)

  let currentPage = 0

  productsSorted.forEach((p, i) => {
    paginatedProducts[currentPage].push(p)

    if ((i + 1) % productsPerPage !== 0) {
      return
    }
    currentPage++

    if (currentPage == lastPage) {
      return
    }
    paginatedProducts[currentPage] = []
  })

  return paginatedProducts
}
