import { createStore } from 'vuex'

import { auth } from './auth/auth.module'
import { supplier } from './user.js/supplier.module'

export default createStore({
  modules: {
    auth,
    supplier,
  },
})
