<template>
  <v-container>
    <h1 data-cy="products-h1">Produtos</h1>

    <v-container class="pa-0 mt-8">
      <v-row class="justify-sm-space-between align-sm-center" no-gutters>
        <v-col cols="12" md="3">
          <v-btn
            size="large"
            variant="flat"
            color="deep-purple"
            data-cy="button-add-product"
            :block="displayXS ? true : false"
            class="text-transform-none depressed mb-3 mb-sm-0"
            @click="
              layout.createProductDialogModel = !layout.createProductDialogModel
            "
          >
            Adicionar
          </v-btn>
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            class="rounded"
            variant="outlined"
            color="deep-purple"
            density="comfortable"
            data-cy="search-field"
            v-model.trim="search"
            label="Pesquisar produto"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-sheet
      v-if="!displayXS"
      class="mx-auto rounded-lg outlined height-720"
      :elevation="displayXS ? '0' : '1'"
    >
      <v-table>
        <thead>
          <tr>
            <th class="text-left">Produto</th>
            <th class="text-left">Descrição</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="item in filteredProducts.length
              ? filteredProducts[layout.pagination.currentPage - 1]
              : products[layout.pagination.currentPage - 1]"
            :key="item.name"
            @click="
              layout.createProductDialogModel = !layout.createProductDialogModel
            "
          >
            <td data-cy="product-name">{{ item.name }}</td>

            <td>{{ item.description }}</td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td colspan="2">
              <v-pagination
                data-cy="pagination"
                v-model="layout.pagination.currentPage"
                :length="filteredProducts.length || products.length"
                rounded="circle"
              ></v-pagination>
            </td>
          </tr>
        </tfoot>
      </v-table>
    </v-sheet>

    <v-list lines="two" v-else>
      <v-list-item
        v-for="(item, i) in filteredProducts.length
          ? filteredProducts[layout.pagination.currentPage - 1]
          : products[layout.pagination.currentPage - 1]"
        :key="i"
        :title="item.name"
        class="rounded px-0 py-4"
        active-color="primary"
        :subtitle="item.description"
      >
        <template v-slot:append>
          <div>
            <v-btn
              @click="
                layout.createProductDialogModel =
                  !layout.createProductDialogModel
              "
              icon="mdi-pencil"
              color="warning"
              variant="text"
            ></v-btn>

            <v-btn
              @click="
                layout.confirmationDialogModel = !layout.confirmationDialogModel
              "
              icon="mdi-delete"
              color="error"
              variant="text"
            ></v-btn>
          </div>
        </template>

        <v-divider class="absolute-bottom"></v-divider>
      </v-list-item>

      <v-pagination
        v-model="layout.pagination.currentPage"
        :length="filteredProducts.length || products.length"
        rounded="circle"
      ></v-pagination>
    </v-list>

    <v-dialog persistent v-model="layout.createProductDialogModel" width="480">
      <v-sheet class="pa-4">
        <v-form @submit.prevent="onSubmit">
          <fieldset>
            <div class="d-flex justify-space-between align center">
              <legend
                data-cy="sheet-add-product__title"
                class="font-weight-medium mb-4 d-block"
              >
                Adicionar produto
              </legend>

              <v-btn
                size="small"
                variant="flat"
                class="ml-auto"
                icon="mdi-close"
                @click="
                  layout.createProductDialogModel =
                    !layout.createProductDialogModel
                "
                data-cy="sheet-add-product__button-close"
              ></v-btn>
            </div>

            <v-text-field
              label="Nome"
              class="mb-2"
              variant="outlined"
              v-model.trim="name"
              hide-details="auto"
              color="deep-purple"
              density="comfortable"
              data-cy="sheet-add-product__name-field"
              :error-messages="form.isSubmitted ? errors.name : ''"
            ></v-text-field>

            <v-textarea
              shaped
              rows="3"
              auto-grow
              class="mb-2"
              row-height="25"
              label="Descrição"
              variant="outlined"
              name="description"
              color="deep-purple"
              hide-details="auto"
              v-model.trim="description"
              data-cy="sheet-add-product__description-field"
              :error-messages="form.isSubmitted ? errors.description : ''"
            ></v-textarea>

            <!-- <v-text-field
              prefix="R$"
              label="Valor"
              v-model="amount"
              variant="outlined"
              color="deep-purple"
              density="comfortable"
              :error-messages="form.isSubmitted ? errors.amount : ''"
            ></v-text-field> -->

            <p
              v-if="layout.form.errorSubmittingProduct"
              class="px-4 my-2 text-error font-weight-medium"
            >
              Não foi possível enviar produto.
            </p>
          </fieldset>

          <div class="d-flex">
            <!-- <v-btn
              v-if="!displayXS"
              color="error"
              class="text-none"
              variant="outlined"
              @click="
                layout.createProductDialogModel =
                  !layout.createProductDialogModel
              "
            >
              Deletar
            </v-btn> -->

            <v-spacer></v-spacer>

            <v-btn
              color="pink"
              variant="flat"
              class="text-none mr-4 d-block"
              :disabled="layout.isLoading"
              @click="
                layout.createProductDialogModel =
                  !layout.createProductDialogModel
              "
              data-cy="sheet-add-product__button-cancel"
            >
              Cancelar
            </v-btn>

            <v-btn
              type="submit"
              variant="flat"
              color="primary"
              class="text-none"
              :loading="layout.isLoading"
              :disabled="layout.isLoading"
              data-cy="sheet-add-product__button-submit"
            >
              Adicionar
            </v-btn>
          </div>
        </v-form>
      </v-sheet>
    </v-dialog>

    <ConfirmationDialog
      title="Excluir produto"
      :dialogModel="layout.confirmationDialogModel"
      @click="handleClickConfirmationDialogButton"
      :message="'Tem certeza que deseja excluir este produto'"
    />
  </v-container>
</template>

<script>
  import { reactive, watch, ref } from 'vue'

  import ConfirmationDialog from '@/components/modals/ConfirmationDialog.vue'

  import { useStore } from 'vuex'
  import { useForm, useField } from 'vee-validate'
  import * as yup from 'yup'

  export default {
    name: 'HomeView',

    components: { ConfirmationDialog },

    setup() {
      const productSchema = yup.object({
        name: yup.string().required('Campo obrigatório.'),
        description: yup.string().required('Campo obrigatório.'),
        // amount: yup.string().required('Campo obrigatório'),
      })

      const { handleSubmit, setFieldValue, errors, submitCount, handleReset } =
        useForm({
          validationSchema: productSchema,
        })

      const { value: name } = useField('name')
      const { value: description } = useField('description')
      const { value: amount } = useField('amount')

      const search = ref('')

      const layout = reactive({
        isLoading: false,
        createProductDialogModel: false,
        confirmationDialogModel: false,
        pagination: {
          currentPage: 1,
        },
        form: {
          errorSubmittingProduct: false,
        },
      })

      const form = reactive({
        isSubmitted: false,
      })

      const store = useStore()

      const onSubmit = handleSubmit(async ({ name, description }, actions) => {
        layout.isLoading = true

        try {
          await store.dispatch('supplier/addProduct', { name, description })

          actions.resetForm()
        } catch (error) {
          layout.form.errorSubmittingProduct = true
        } finally {
          layout.isLoading = false
        }
      })

      store
        .dispatch('supplier/getAllProducts')
        .then(() => {
          store.dispatch('supplier/paginateProducts')
        })
        .catch()

      watch(name, () => {
        if (layout.form.errorSubmittingProduct) {
          layout.form.errorSubmittingProduct = false
        }
      })

      watch(description, () => {
        if (layout.form.errorSubmittingProduct) {
          layout.form.errorSubmittingProduct = false
        }
      })

      watch(
        () => layout.createProductDialogModel,
        (newValue) => {
          if (newValue === false) {
            handleReset()
          }
        }
      )

      watch(submitCount, (newValue) => {
        if (newValue > 0) {
          form.isSubmitted = true
        }

        if (newValue === 0) {
          form.isSubmitted = false
        }
      })

      watch(search, (newValue) => {
        store.dispatch('supplier/filterProducts', newValue)
        layout.pagination.currentPage = 1
      })

      return {
        setFieldValue,
        submitCount,
        onSubmit,
        errors,

        name,
        amount,
        description,

        form,
        layout,
        search,
      }
    },

    computed: {
      displayXS() {
        return this.$vuetify.display.xs
      },

      products() {
        return this.$store.getters['supplier/paginatedProducts']
      },

      filteredProducts() {
        return this.$store.getters['supplier/filteredProducts']
      },
    },

    methods: {
      handleClickConfirmationDialogButton(value) {
        this.layout.confirmationDialogModel = false
      },
    },
  }
</script>

<style scoped>
  tbody tr:hover {
    background-color: #ddd;
    cursor: pointer;
  }

  ::v-deep .v-pagination__list {
    justify-content: flex-end;
  }

  fieldset {
    border: none;
  }

  legend {
    font-size: 1.5rem;
    font-weight: 500;
  }
  .absolute-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
  }
</style>
