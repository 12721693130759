<template>
  <v-app>
    <v-navigation-drawer
      v-if="isLoggedIn"
      theme="dark"
      class="bg-deep-purple pt-2"
      :permanent="displayXS ? false : true"
    >
      <v-list>
        <v-list-item
          prepend-avatar="https://randomuser.me/api/portraits/women/85.jpg"
          title="John Doe"
        ></v-list-item>
      </v-list>

      <v-divider class="my-4"></v-divider>

      <v-list color="transparent" nav>
        <v-list-item
          link
          title="Produtos"
          prepend-icon="mdi-view-dashboard"
        ></v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn @click="signOut" data-cy="signOut-button" variant="text" block>
            <v-icon class="mr-4">mdi-logout</v-icon> Sair
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  export default {
    name: 'App',

    computed: {
      displayXS() {
        return this.$vuetify.display.xs
      },

      isLoggedIn() {
        return this.$store.getters['auth/loggedIn']
      },
    },

    methods: {
      signOut() {
        this.$store.dispatch('auth/signOut')
        this.$router.push('/login')
      },
    },
  }
</script>

<style>
  :root {
    --app-purple-1: #461fc0;
    --app-purple-2: #552de2;
    --app-purple-3: #a096de;
  }
  .bg-app-purple-1 {
    background-color: var(--app-purple-1);
    color: white;
  }

  .text-app-purple-3 {
    color: var(--app-purple-3);
  }

  .text-transform-none {
    text-transform: none !important;
  }
</style>
