<template>
  <v-container class="fill-height justify-center align-start align-center">
    <v-sheet
      class="rounded-xl grid-two-columns overflow-hidden height-720 p-8 p-sm-0"
      :elevation="displayXS ? '0' : '1'"
      width="960"
    >
      <aside class="d-none d-sm-block bg-app-purple-1 pa-16">
        <h2 class="font-weight-medium mt-30">Busca Auto</h2>

        <h1 class="mt-8 text-h4 pt-14">
          Tudo para o seu automóvel em um só lugar
        </h1>

        <p class="text-subtitle text-app-purple-3 mt-8">
          Anúncios de peças para automóveis é aqui
        </p>
      </aside>

      <main class="pa-0 pa-sm-16 d-flex align-center justify-center">
        <Form
          @submit="onSubmit"
          class="flex-grow-1"
          v-slot="{ errors }"
          :validation-schema="loginSchema"
          @invalid-submit="onInvalidSubmit"
        >
          <fieldset class="py-5">
            <legend class="text-h5 font-weight-bold">Login</legend>

            <Field
              name="email"
              v-slot="{ field }"
              :validateOnBlur="false"
              :validateOnModelUpdate="form.isInvalidSubmitted ? true : false"
              :validateOnChange="false"
            >
              <v-text-field
                class="mb-2"
                label="Email"
                v-bind="field"
                data-cy="email"
                variant="outlined"
                color="deep-purple"
                density="comfortable"
                :error-messages="errors?.email"
              ></v-text-field>
            </Field>

            <Field
              name="password"
              v-slot="{ field }"
              :validateOnBlur="false"
              :validateOnModelUpdate="form.isInvalidSubmitted ? true : false"
              :validateOnChange="false"
            >
              <v-text-field
                label="Senha"
                v-bind="field"
                type="password"
                data-cy="password"
                variant="outlined"
                color="deep-purple"
                density="comfortable"
                :error-messages="errors?.password"
              ></v-text-field>
            </Field>

            <p
              v-if="form.isInvalidSubmittedResponse"
              data-cy="email-or-password-submitted-invalid"
              class="mb-2 text-error px-4"
            >
              Usuário ou senha inválidos!!
            </p>

            <v-btn
              block
              size="large"
              type="submit"
              color="#552de2"
              data-cy="button-submit"
              :loading="layout.isLoading"
              :disabled="layout.isLoading"
              class="dark white-text text-transform-none"
            >
              Entrar
            </v-btn>
          </fieldset>

          <p>
            Ainda não é cadastrado?
            <a
              class="link"
              data-cy="signUp-link"
              @click="$router.push('signup')"
              >Registre-se</a
            >
          </p>
        </Form>
      </main>
    </v-sheet>
  </v-container>
</template>

<script>
  import { defineComponent } from 'vue'

  import { Field, Form } from 'vee-validate'
  import * as yup from 'yup'

  export default defineComponent({
    name: 'LoginView',

    components: { Field, Form },

    setup() {
      const loginSchema = yup.object({
        email: yup
          .string()
          .trim()
          .email('Digite um email válido')
          .required('O campo e-mail precisa ser preenchido'),
        password: yup.string().trim().required('Insira a senha'),
      })

      return {
        loginSchema,
      }
    },

    data: () => ({
      form: {
        isInvalidSubmitted: false,
        isInvalidSubmittedResponse: false,
      },

      layout: {
        isLoading: false,
      },
    }),

    computed: {
      displayXS() {
        return this.$vuetify.display.xs
      },
    },

    methods: {
      async onSubmit({ email, password }) {
        this.layout.isLoading = true
        try {
          await this.$store.dispatch('auth/signIn', { email, password })
          this.$router.push('/')
        } catch (err) {
          this.form.isInvalidSubmittedResponse = true
        } finally {
          this.layout.isLoading = false
        }
      },

      onInvalidSubmit() {
        if (!this.form.isInvalidSubmitted) {
          this.form.isInvalidSubmitted = true
        }
      },
    },
  })
</script>

<style scoped>
  .grid-two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .height-720 {
    min-height: 45rem;
  }

  .mt-30 {
    margin-top: 7.5rem;
  }

  fieldset {
    border: none;
  }

  legend {
    text-align: center;
  }

  ::v-deep .white-text {
    color: white;
  }

  .link {
    color: var(--app-purple-2);
    text-decoration: underline;
    cursor: pointer;

    transition: filter 300ms ease;
  }

  .link:hover {
    filter: brightness(0.65);
  }
  @media (max-width: 600px) {
    .grid-two-columns {
      grid-template-columns: 1fr;
    }

    .height-720 {
      min-height: max-content;
    }
  }
</style>
