import { getLocalAccessToken } from '@/services/token.service'
import { signIn, signOut, signUp } from '../../services/auth/auth.service'

const token = getLocalAccessToken()
const initialState = token
  ? { status: { loggedIn: true } }
  : { status: { loggedIn: false } }

export const auth = {
  namespaced: true,
  state: initialState,

  getters: {
    loggedIn(state) {
      return state.status.loggedIn
    },
  },

  actions: {
    async signIn({ commit }, user) {
      try {
        const response = await signIn(user)

        commit('signInSuccess')

        return response
      } catch (err) {
        commit('signInFailure')

        throw new Error(err)
      }
    },

    signOut({ commit }) {
      signOut()
      commit('signOut')
    },

    async signUp({ commit }, user) {
      try {
        await signUp(user)

        commit('signUpSuccess')
      } catch (err) {
        commit('signUp')

        throw new Error(err)
      }
    },
  },

  mutations: {
    signInSuccess(state) {
      state.status.loggedIn = true
    },

    signInFailure(state) {
      state.status.loggedIn = false
    },

    signOut(state) {
      state.status.loggedIn = false
    },

    signUpSuccess(state) {
      state.status.loggedIn = false
    },

    signUp(state) {
      state.status.loggedIn = false
    },
  },
}
