import { createRouter, createWebHashHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import SignUp from '../views/SignUpView.vue'

import { getLocalAccessToken, isValid } from '@/services/token.service'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home',
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp,
    meta: {
      title: 'Registrar-se',
    },
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`
  const token = getLocalAccessToken()

  if (to.meta.requiresAuth && !isValid(token)) {
    next({ name: 'login' })
    return
  } else if (to.name == 'login' && isValid(token)) {
    next({ name: 'home' })
    return
  } else if (to.name == 'signup' && isValid(token)) {
    next({ name: 'home' })
  }

  next()
})

export default router
