import { buscaAutoInstance } from '../api'

async function get() {
  try {
    const response = await buscaAutoInstance.get(
      '/fornecedor/produtos/getbyfornecedor'
    )

    return response.data
  } catch (error) {
    throw new Error(error)
  }
}

async function set({ name, description }) {
  try {
    const response = await buscaAutoInstance.post('/produto/add', {
      nome: name,
      descricao: description,
    })

    return response.data
  } catch (error) {
    throw new Error(error)
  }
}

export default {
  get,
  set,
}
