import { buscaAutoInstance } from './api'
import { getLocalAccessToken, isValid } from './token.service'

const FORBIDDEN = 403

export const buscaAutoInterceptors = (store, router) => {
  buscaAutoInstance.interceptors.request.use(
    (config) => {
      const token = getLocalAccessToken()

      if (token?.access_token) {
        config.headers['Authorization'] = 'Bearer ' + token.access_token
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  buscaAutoInstance.interceptors.response.use(
    (res) => {
      return res
    },
    (err) => {
      const token = getLocalAccessToken()

      if (err.response.status == FORBIDDEN) {
        if (!isValid(token)) {
          store.dispatch('auth/signOut')
          router.push('login')
        }
      }

      return Promise.reject(err)
    }
  )
}
