import SupplierService from '@/services/user/supplier.service'
import { paginateByDate } from '@/utils/products'

import _ from 'lodash'

export const supplier = {
  namespaced: true,
  state: {
    products: [],
    paginatedProducts: [[]],
    filteredProducts: [],
  },

  getters: {
    products(state) {
      return state.products
    },

    paginatedProducts(state) {
      return state.paginatedProducts
    },

    filteredProducts(state) {
      return state.filteredProducts
    },
  },

  actions: {
    async getAllProducts({ commit }) {
      try {
        let products = await SupplierService.get()

        products = products.map((p) => {
          return {
            id: p.id,
            name: p.nome,
            description: p.descricao,
            created_at: p.created_at,
            updated_at: p.updated_at,
          }
        })

        commit('setProducts', products)
      } catch (err) {
        console.error(err)
      }
    },

    async addProduct({ commit, dispatch }, { name, description }) {
      try {
        const product = await SupplierService.set({ name, description })

        commit('addProduct', {
          id: product.id,
          name: product.nome,
          description: product.descricao,
          created_at: product.created_at,
          updated_at: product.updated_at,
        })

        dispatch('paginateProducts')

        return product
      } catch (error) {
        throw new Error(error)
      }
    },

    paginateProducts({ commit, state }) {
      const paginatedProducts = paginateByDate(state.products)

      commit('setPaginatedProducts', paginatedProducts)
    },

    filterProducts({ commit, state }, searchField) {
      const fondedProducts = state.products.filter((p) =>
        p.name.toLowerCase().includes(searchField.toLowerCase())
      )

      const paginatedProducts = paginateByDate(fondedProducts)

      commit('setFilteredProducts', paginatedProducts)
    },
  },

  mutations: {
    setProducts(state, products) {
      state.products = products
    },

    addProduct(state, product) {
      state.products.push(product)
    },

    setPaginatedProducts(state, products) {
      state.paginatedProducts = _.cloneDeep(products)
    },

    setFilteredProducts(state, products) {
      state.filteredProducts = _.cloneDeep(products)
    },
  },
}
