import { buscaAutoInstance } from '../api'

import { removeLocalToken, setLocalToken } from '../token.service'

export async function signIn({ email, password }) {
  try {
    const response = await buscaAutoInstance.post('/fornecedor/auth/login', {
      email: email,
      password: password,
    })

    if (response?.data?.access_token) {
      setLocalToken(response.data)
    }

    return response?.data
  } catch (err) {
    throw new Error(err)
  }
}

export function signOut() {
  removeLocalToken()
}

export async function signUp({ email, password }) {
  try {
    const response = await buscaAutoInstance.post('/fornecedor/auth/register', {
      email: email,
      password: password,
    })

    return response?.data
  } catch (err) {
    throw new Error(err)
  }
}
