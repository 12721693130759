<template>
  <section class="d-flex align-center">
    <template v-for="(item, i) in steps" :key="item.id">
      <div
        v-ripple
        :data-cy="`button-${item.id}`"
        @click="$emit('update:step', item.id)"
        class="d-flex flex-column align-center justify-center pa-2 flex-basis-175 rounded cursor-pointer"
      >
        <v-btn
          variant="flat"
          :ripple="false"
          :icon="item.icon"
          :data-cy="`button__icon-button-${item.id}`"
          :color="getColor(step, item.id)"
        ></v-btn>

        <p
          :data-cy="`button__text-${item.id}`"
          class="mt-1 font-weight-medium text-center"
        >
          {{ item.text }}
        </p>
      </div>

      <v-divider v-if="i + 1 != steps.length"></v-divider>
    </template>
  </section>
</template>

<script>
  export default {
    name: 'AppStepper',
    props: {
      steps: {
        type: Array,
        default: () => [],
        required: true,
      },
      step: {
        type: Number,
        default: () => 0,
        required: true,
      },
      errors: {
        type: Array,
        default: () => [],
      },
    },

    computed: {
      stepErrors() {
        return this.errors
      },
    },

    methods: {
      getColor(step, id) {
        const objError = this.arrayToObject(this.stepErrors)

        if (objError[id] == id) {
          return 'error'
        }

        if (step === id) {
          return 'deep-purple'
        }

        return 'grey-lighten-2'
      },

      arrayToObject(array) {
        const object = {}

        for (let item of array) {
          object[item.step] = item.step
        }

        return object
      },
    },
    emits: ['update:step'],
  }
</script>

<style scoped>
  hr {
    margin-top: -2.75rem;
    margin-inline: -1rem;
  }

  .flex-basis-175 {
    flex-basis: 10.9375rem;
  }

  p {
    min-height: 3rem;
  }

  .cursor-pointer {
    cursor: pointer;
  }
</style>
